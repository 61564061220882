import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ReadBlogContents from '../contents/ReadBlogContents'

function ReadBlog() {
  return (
    <>
    <div className='min-h-screen flex flex-col'>
        <Navbar />
        <ReadBlogContents />
        <Footer />
    </div>
    </>
  )
}

export default ReadBlog