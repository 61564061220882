import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import placeholderimg from '../assets/placeholder-image.png'
import accent4 from '../assets/Accent4.png'

function SolutionsAndServicesContents() {
    const [hoverIndex, setHoverIndex] = useState(null)
    const [hoverServiceIndex, setHoverServiceIndex] = useState(null)

    const solutions = [
        {menu: "Workload Automation", image: require('../assets/solutions/Workload_Automation.jpg')},
        {menu: "Infrastructure & Security Solutions", image: require('../assets/solutions/Infrastructure_Security.jpg')},
        {menu: "Backups & Disaster Recovery", image: require('../assets/solutions/Backup_Disaster.jpg')},
        {menu: "Enterprise Cloud Solutions", image: require('../assets/solutions/Enterprise_Cloud_solutions.jpg')},
        {menu: "Data Analytics & Business Intelligence", image: require('../assets/solutions/Data_Analystics_Business_Intelligence.jpg')},
        {menu: "Software Automation Testing", image: require('../assets/solutions/Software_Automation_Testing.jpg')},
    ]

    const services = [
        {menu: "Premium Support and Maintenance", image: require('../assets/solutions/software engineer.jpg')},
        {menu: "Standard Support and Maintenance", image: require('../assets/solutions/customer service.jpg')},
        {menu: "Manage File Transfer (MFT) Manage Service", image: require('../assets/solutions/MFT.jpg')},
        {menu: "Workload Automation Manage Service", image: require('../assets/solutions/Workload Automation MS.jpg')},
        {menu: "Software Testing Manage Service", image: require('../assets/solutions/Software Testing MS.jpg')},
    ]
   
  return (
    <>
    <div className='flex flex-grow w-full justify-center lg:py-40 py-24 items-center flex-col gap-12 relative'>
        <div className='-z-10 absolute pt-40'>
            <img src={accent4} style={{animation: `spin linear 20s infinite`}} alt=''/>
        </div>
        <div className='flex flex-col gap-1 lg:w-4/6 w-11/12'>
            <span className='font-plusjakarta text-blueaccent'>Solutions and Services</span>
            <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Explore What We Do</span>
        </div>
        <div className='flex flex-col gap-12 lg:w-4/6 w-11/12'>
            <div className='flex flex-col gap-4'>
                <span className='font-plusjakarta font-bold lg:text-2xl text-xl'>Solutions</span>
                <div className='w-full grid lg:grid-cols-3 grid-cols-1 lg:gap-10 gap-5'>
                    {solutions.map ((solutions, index) => (
                        <Link to={`/solutions-and-services/solutions/${solutions.menu}`} key={index} className={`w-full h-72 flex flex-col justify-end rounded-xl hover:bg-opacity-0 relative ${hoverIndex === index ? 'scale-105 shadow-xl cursor-pointer' : ''} ${hoverIndex !== index && hoverIndex !== null ? 'bg-black bg-opacity-75' : ''} transition-all duration-100`} onMouseEnter={() => {setHoverIndex(index)}} onMouseLeave={() => {setHoverIndex(null)}}>
                            <div className='w-full h-full absolute -z-10'><img src={solutions.image ? solutions.image : placeholderimg} className='w-full h-full rounded-xl' alt='' /></div>
                            <div className={`w-full h-16 font-raleway font-bold flex justify-start items-center rounded-xl px-5 bg-black bg-opacity-75 ${hoverIndex === index ? 'text-blueaccent' : 'text-white'}`}>{solutions.menu}</div>
                        </Link>
                    ))}
                </div>
            </div>
            <div className='flex flex-col gap-4'>
                <span className='font-plusjakarta font-bold lg:text-2xl text-xl'>Services</span>
                <div className='w-full grid lg:grid-cols-3 grid-cols-1 lg:gap-10 gap-5'>
                    {services.map (({menu, image}, index) => (
                        <Link to={`/solutions-and-services/services/${menu}`} key={index} className={`w-full h-72 flex flex-col justify-end rounded-xl hover:bg-opacity-0 relative ${hoverServiceIndex === index ? 'scale-105 shadow-xl cursor-pointer' : ''} ${hoverServiceIndex !== index && hoverServiceIndex !== null ? 'bg-black bg-opacity-75' : ''} transition-all duration-100`} onMouseEnter={() => {setHoverServiceIndex(index)}} onMouseLeave={() => {setHoverServiceIndex(null)}}>
                            <div className='w-full h-full absolute -z-10'><img src={image ? image : placeholderimg} className='w-full h-full rounded-xl' alt='' /></div>
                            <div className={`w-full h-16 font-raleway font-bold flex justify-start items-center rounded-xl px-5 bg-black bg-opacity-75 ${hoverServiceIndex === index ? 'text-blueaccent' : 'text-white'}`}>{menu}</div>
                        </Link>
                    ))}
                </div>
            </div>
       </div>
    </div>
    </>
  )
}

export default SolutionsAndServicesContents