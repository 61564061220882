import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HomeContents from '../contents/HomeContents'

function Home() {
  return (
    <>
    <div className='min-h-screen flex flex-col lg:overflow-visible overflow-hidden'>
        <Navbar />
        <HomeContents />
        <Footer />
    </div>
    </>
  )
}

export default Home