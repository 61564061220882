import React, {useState} from 'react'
import blogsAndNewsData from '../helpers/BlogsData.json'
import placeholderimg from '../assets/placeholder-image.png'
import accent1 from '../assets/Accent1.png'
import { Link } from 'react-router-dom'

function BlogsAndNewsContents() {
    const [hoverIndex, setHoverIndex] = useState(null)

    function custom_sort(a, b) {
        return new Date(b.last_updated).getTime() - new Date(a.last_updated).getTime();
    }

    const sortedNews = blogsAndNewsData[0].blogs.sort(custom_sort)
  
  return (
    <>
    <div className='flex flex-grow w-full flex-col items-center lg:py-24 py-10 relative'>
        <div className='w-full h-full absolute flex justify-end items-end bottom-10 right-20'>
            <img src={accent1} alt='' className='w-96' style={{animation: `spin linear 20s infinite`}}/>
        </div>
        <div className='lg:w-4/6 w-11/12 flex flex-col gap-1 mb-12'>
            <span className='font-plusjakarta text-blueaccent'>News</span>
            <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Read our latest IT services news</span>
        </div>
        <div className='lg:w-4/6 w-11/12 flex flex-col lg:gap-10 gap-5 relative'>
            {sortedNews.map((data, index) => (
                <Link to={`/news/${data.title}`} className={`w-full lg:h-60 flex lg:gap-5 gap-2 border-b-2 border-blueaccent lg:p-5 py-5 hover:text-bluehover ${hoverIndex === index ? 'scale-105 shadow-xl cursor-pointer' : ''}`} key={index} onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)}>
                    <div className='lg:w-80 lg:h-40 w-40 h-32 flex justify-center items-center bg-slate-200 rounded-lg'>
                        <img src={data.image ? data.image : placeholderimg} alt='' className='w-full h-full object-cover' />
                    </div>
                    <div className='w-full flex flex-col gap-2'>
                        <span className='font-plusjakarta font-bold lg:text-2xl text-xl'>{data.title}</span>
                        <span className='lg:block hidden lg:font-raleway'>{data.text[0].paragraph}</span>
                    </div>
                </Link>
            ))}
        </div>
    </div>
    </>
  )
}

export default BlogsAndNewsContents