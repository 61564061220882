import React from 'react'
import { Link, useParams } from 'react-router-dom'
import blogsData from '../helpers/BlogsData.json'
import placeholderimg from '../assets/placeholder-image.png'
import { IoIosArrowBack } from "react-icons/io";
import accent1 from "../assets/Accent1.png"

function ReadBlogContents() {
    const {title} = useParams()

    const blogIndex = blogsData[0].blogs.findIndex(item => item.title === title)
    const selectedBlog = blogsData[0].blogs[blogIndex]

  return (
    <>
    <div className='flex flex-grow w-full flex-col items-center lg:py-24 py-10 relative'>
        <div className='lg:w-4/6 w-11/12 flex flex-col gap-12 relative'>
            <div className='w-full h-12 flex justify-center items-center absolute top-52 -z-10'>
                <img src={accent1} alt='' className='' style={{animation: `spin linear 20s infinite`}}/>
            </div>
            <div className='w-full lg:h-12 h-10 flex justify-start items-center text-blueprimary hover:text-bluehover'>
                <Link to={'/news'}><IoIosArrowBack className='h-full lg:w-12 w-10'/></Link>
            </div>
            <div className='w-full lg:flex lg:flex-row flex flex-col gap-5 lg:gap-12 lg:justify-between lg:items-center'>
                <div className='w-full lg:w-auto font-plusjakarta lg:text-4xl text-2xl font-bold'>{selectedBlog.title}</div>
                <div className='lg:w-2/5 w-full lg:h-96 h-80 bg-slate-200 rounded-lg'>
                    <img src={selectedBlog.image ? selectedBlog.image : placeholderimg} alt='' className='object-cover w-full h-full'/>
                </div>
            </div>
            <div className='w-full flex flex-col gap-8 font-raleway'>
                {selectedBlog.text.map((data, index) => (
                    <div key={index}>{data.paragraph}</div>
                ))}
            </div>
        </div>
    </div>
    </>
  )
}

export default ReadBlogContents