import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import CategoryContents from '../contents/CategoryContents'

function Category() {
  return (
    <div className='min-h-screen flex flex-col'>
        <Navbar />
        <CategoryContents />
        <Footer />
    </div>
  )
}

export default Category