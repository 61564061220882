import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

function NotFound() {
  return (
    <>
    <div className='min-h-screen flex flex-col'>
        <Navbar />
        <div className='flex flex-grow w-full justify-center py-24 items-center flex-col gap-12 font-plusjakarta lg:text-4xl text-2xl font-bold'>Page Not Found</div>
        <Footer />
    </div>
    </>
  )
}

export default NotFound