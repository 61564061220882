import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import CareersContents from '../contents/CareersContents'

function Careers() {
  return (
    <>
    <div className='min-h-screen flex flex-col overflow-hidden'>
        <Navbar />
        <CareersContents />
        <Footer />
    </div>
    </>
  )
}

export default Careers