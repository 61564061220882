import React, {useState, useRef, useEffect} from 'react'
import { useLocation, Link } from 'react-router-dom'
import logo from '../assets/Logo Bajau_Gelap.png'
import solutionsData from '../helpers/SolutionsData.json'
import { HiOutlineMenu, HiX } from "react-icons/hi";
import { FaRegQuestionCircle } from "react-icons/fa";

function Navbar() {
    const [hoverOnSolutions, setHoverOnSolutions] = useState(false)
    const [hasNavigated, setHasNavigated] = useState(false)
    const [menuToggle, setMenuToggle] = useState(false)
    const [hoverOnSupport, setHoverOnSupport] = useState(false)
    const navbarRef = useRef(null)
    const location = useLocation()

    useEffect(() => {
        if (location.pathname.includes("/solutions-and-services")){
            setHasNavigated(true)
        }
    },[location.pathname])

    const routes = [
        {menu: "Home", to: "/"},
        {menu: "Solutions & Services", to: "/solutions-and-services"},
        {menu: "About Us", to: "/about-us"},
        {menu: "Contact Us", to: "/contact-us"},
        // {menu: "News", to: "/news"},
        {menu: "Careers", to: "/careers"},
    ]
  return (
    <>
    <div className='hidden lg:flex lg:flex-col lg:h-20 lg:w-full lg:justify-center lg:items-center lg:fixed lg:top-0 lg:bg-white lg:z-20' ref={navbarRef} onMouseLeave={() => {setHasNavigated(false); setHoverOnSolutions(false); setHoverOnSupport(false)}} onMouseEnter={location.pathname !== '/solutions-and-services' ? () => {setHasNavigated(false); setHoverOnSolutions(false)} : null}>
        <div className='flex justify-between w-4/6 items-center'>
            <div className='h-16'>
                <Link to={"/"}><img src={logo} className='h-16' alt=''/></Link>
            </div>
            <div className='flex items-center justify-between gap-10 font-plusjakarta font-[16px]'>
                {routes.map(({menu, to}, index) => (
                    <Link to={to} 
                    className={`${location.pathname === to || (menu === 'Solutions & Services' && location.pathname.includes('solutions-and-services')) || (menu === 'News' && location.pathname.includes('news'))? 'text-bluehover font-bold border-b-2 border-bluehover': 'text-black'} hover:text-bluehover hover:cursor-pointer`} 
                    key={index} 
                    onMouseEnter={menu === "Solutions & Services" ? () => {setHoverOnSolutions(true); setHoverOnSupport(false)} : () => {setHoverOnSolutions(false); setHasNavigated(false); setHoverOnSupport(false)}}>{menu}</Link>
                ))}
            </div>
            <div>
                <div className={`flex gap-2 justify-center items-center hover:text-bluehover hover:cursor-pointer ${hoverOnSupport ? 'text-bluehover' : ''}`}>
                <div className='font-plusjakarta flex justify-center items-center rounded-md' onMouseEnter={() => {setHoverOnSupport(true)}}>Support</div>
                <FaRegQuestionCircle />
                </div>
                {/* <MdLiveHelp className='w-6 h-6 text-blueprimary hover:text-bluehover' onMouseEnter={() => {setHoverOnSupport(true)}}/> */}
            </div>
        </div>
        {hoverOnSolutions && !hasNavigated && (
        <div className='h-auto w-full flex justify-center items-center bg-white absolute z-20 py-10 shadow-lg' onMouseLeave={() => {setHoverOnSolutions(false)}} style={{ top: navbarRef.current ? navbarRef.current.offsetHeight : 0 }}>
            <div className='w-4/6 grid grid-cols-6 gap-8'>
                <div className='col-span-2 flex justify-end'>
                    <span className='font-plusjakarta text-2xl font-bold'>Solutions</span>
                </div>
                <div className='col-span-4'>
                    <div className="w-full grid grid-cols-2 gap-1 gap-y-5">
                        {solutionsData[0].solutions.map((data, index) => (
                            <div key={index} className="flex flex-col gap-1 font-plusjakarta">
                                <Link to={`/solutions-and-services/solutions/${data.name}`} className="font-bold hover:text-bluehover hover:cursor-pointer">{data.name}</Link>
                                <div className="flex flex-col gap-1">
                                {data.solution_items && data.solution_items.length !== 0 ? (
                                data.solution_items.map((solutionItem, idx) => (
                                    <Link to={`/solutions-and-services/solutions/${data.name}/${solutionItem.name}`} key={idx} className="hover:text-bluehover hover:cursor-pointer">{solutionItem.name}</Link>
                                ))
                                ) : null}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='col-span-2 flex justify-end'><span className='font-plusjakarta text-2xl font-bold'>Support and Managed Services</span></div>
                <div className='col-span-4'>
                    <div className="w-full grid grid-cols-2 gap-1 gap-y-5 font-plusjakarta">
                        {solutionsData[1].services.map((data, index) => (
                            <Link key={index} to={`/solutions-and-services/services/${data.name}`} className="font-bold hover:text-bluehover hover:cursor-pointer">{data.name}</Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>)}
        {hoverOnSupport && (
            <div className='h-auto w-full flex justify-end items-center absolute z-20 py-10 bg-white shadow-lg' onMouseLeave={() => {setHoverOnSolutions(false)}} style={{ top: navbarRef.current ? navbarRef.current.offsetHeight : 0 }}>
                <span className='mx-24 font-raleway'>Are you an existing client? <Link to={'https://support.bajau.com/'} target='_blank' className='text-blueprimary hover:text-bluehover font-bold'>Click here to submit support ticket</Link></span>
            </div>
        )}
    </div>
    <div className='lg:hidden w-full h-20 fixed top-0 z-20 flex justify-center items-center bg-white'>
        <div className='w-9/12 flex justify-between'>
            <div className='h-12'>
                <Link to={"/"}><img src={logo} className='h-12' alt=''/></Link>
            </div>
        </div>
        <div className='w-10 h-10 flex justify-center items-center' onClick={() => setMenuToggle(true)}><HiOutlineMenu className='w-8 h-8 text-blueprimary'/></div>
    </div>
    {menuToggle && (
        <div className='lg:hidden h-screen w-10/12 fixed top-0 right-0 bg-white z-20 p-5 shadow-xl' >
            <div className='w-full h-full flex flex-col justify-start gap-10'>
                <div className='flex flex-col gap-4' onClick={() => setHoverOnSupport(false)}>
                <div className='h-12 w-full flex justify-end'><HiX className='w-8 h-8 text-bluehover' onClick={() => {setMenuToggle(false); setHoverOnSupport(false)}}/></div>
                <div className='flex flex-col justify-start items-start gap-2 font-plusjakarta'>
                    {routes.map(({menu, to}, index) => (
                        <Link to={to} 
                        className='py-2 flex justify-between'
                        key={index} 
                        onMouseEnter={menu === "Solutions & Services" ? () => {setHoverOnSolutions(true)} : () => {setHoverOnSolutions(false); setHasNavigated(false)}}>
                            <span className={`${location.pathname === to || (menu === 'Solutions & Services' && location.pathname.includes('solutions-and-services')) ? 'text-bluehover font-bold border-b-2 border-bluehover': 'text-black'}`}>{menu}</span></Link>
                    ))}
                </div>
                </div>
                <div className='w-full'>
                    {hoverOnSupport ? (
                        <div className='w-full flex justify-between items-center'>
                        <div className='flex flex-col gap-2 justify-start font-plusjakarta'>
                            <span>Are you an existing client?</span>
                            <Link to={'https://support.bajau.com/'} target='_blank' className='text-bluehover'>Click here to submit support ticket</Link>
                        </div>
                        <div className='w-8 h-8 flex justify-center items-center' onClick={() => {setHoverOnSupport(false)}}>
                            <HiX className='h-4 w-4' />
                        </div>
                        </div>
                    ) : 
                    (
                        <div className='flex gap-2 items-center font-plusjakarta z-30' onClick={() => {setHoverOnSupport(true)}}>
                            <span>Support</span>
                            <FaRegQuestionCircle />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )}
    </>
  )
}

export default Navbar