import React, {useState} from 'react'
import { Carousel } from 'flowbite-react'
import { Link, useNavigate } from 'react-router-dom'
import accent1 from '../assets/Accent1.png'
import accent2 from '../assets/Accent2.png'
import accent3 from '../assets/Accent3.png'
import clientbg from '../assets/client-background.jpg'
import taglinePic from '../assets/home/taglinePic.jpg'
import placeholderimg from '../assets/placeholder-image.png'
import clientCentered from '../assets/home/Client Centered.jpg'
import deliverResults from '../assets/home/Deliver Results.jpg'
import trustedPartner from '../assets/home/Trusted Partnership.jpg'
import client1 from '../assets/clients/Client_UOB.png'
import client2 from '../assets/clients/Client_Permata.png'
import client3 from '../assets/clients/Client_Danamon.png'
import client4 from '../assets/clients/Client_Icon+.png'
import client5 from '../assets/clients/Client_Citra.jpeg'
import client6 from '../assets/clients/Client_Coca.png'
import client7 from '../assets/clients/Client_Telkomsel.png'
import client8 from '../assets/clients/Client_Sequis.png'
import client9 from '../assets/clients/Client_Tokio.png'
import client10 from '../assets/clients/Client_KDDI.png'
import client11 from '../assets/clients/Client_Sumber.png'
import client12 from '../assets/clients/Client_Sampoerna.png'
import client13 from '../assets/clients/Client_Mandiri.png'
import client14 from '../assets/clients/Client_Chubb.png'
import client15 from '../assets/clients/Client_BCA.png'
import client16 from '../assets/clients/Client_BI.png'
import client17 from '../assets/clients/Client_CIMB.png'
import client18 from '../assets/clients/Client_BTN.png'
import client19 from '../assets/clients/Client_Astra.png'
import client20 from '../assets/clients/Client_Panin.png'
import principalsData from '../helpers/PrincipalsData.json'
import Button from '../components/Button'

function HomeContents() {
    const [solutionIndex, setSolutionIndex] = useState(0)
    const topSolutions = [
        {solution: "High-Availability Database", description: "Always available, just for you!", image: require("../assets/solutions/High_Availability.jpg"), to:"/solutions-and-services/solutions/Backups & Disaster Recovery/High-Availability Database"},
        {solution: "Service Orchestration and Automation", description: "Orchestrate your services now, it's smart and fast!", image:require("../assets/solutions/Service_Orchestration.jpg"), to:"/solutions-and-services/solutions/Workload Automation/Service Orchestration and Automation"},
        {solution: "System Provisioning and Configuration Management", description: "From code to infrastructure, all in sync", image:require("../assets/solutions/System_Provisioning.jpg"), to:"/solutions-and-services/solutions/Infrastructure & Security Solutions/System Provisioning and Configuration Management"},
        {solution: "Enterprise File Sync and Sharing", description: "Stay connected, stay secure with EFSS", image:require("../assets/solutions/Enterprise_File_Sync_Share.jpg"), to:"/solutions-and-services/solutions/Enterprise Cloud Solutions/Enterprise File Sync and Sharing"},
        {solution: "Software Automation Testing", description: "The future of testing, stress less", image:require("../assets/solutions/Software_Automation_Testing.jpg"), to:"/solutions-and-services/solutions/Software Automation Testing"},
    ]
    const navigate = useNavigate()

    function hoverSolution(index) {
        setSolutionIndex(index)
    }

    function navToAboutUs() {
        navigate('/about-us')
    }

    const customTheme = {
        root: {
          base: "relative h-full w-full",
          leftControl:
            "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
          rightControl:
            "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
        },
        indicators: {
          active: {
            off: "bg-black/50 hover:bg-black/70 dark:bg-gray-800/50 dark:hover:bg-gray-800",
            on: "bg-blueaccent",
          },
          base: "h-2 w-2 rounded-full",
        },
        control: {
            base: "inline-flex h-8 w-8 items-center justify-center rounded-full lg:group-hover:bg-blueprimary/50 bg-blueaccent/50 lg:bg-transparent group-focus:outline-none group-focus:ring-4 group-focus:ring-blueprimary sm:h-10 sm:w-10",
            icon: "h-5 w-5 lg:text-blueprimary text-white dark:text-gray-800 sm:h-6 sm:w-6"
        },
        scrollContainer: {
            base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll overflow-x-hidden lg:scroll-smooth rounded-lg",
            snap: "snap-x"
        }
      };
  return (
    <div className='flex flex-grow w-full flex-col items-center pt-20'>
        <div className='lg:h-[800px] h-[500px] w-full relative'>
            <div className='-z-10 absolute lg:pt-40 pt-5'>
                <img src={accent1} style={{animation: `spin linear 20s infinite`}} alt=''/>
            </div>
            <Carousel pauseOnHover theme={customTheme}>
                <div className='h-full lg:flex lg:flex-row flex flex-col'>
                    <div className='h-full w-full lg:flex lg:flex-row lg:justify-end flex justify-center'>
                        <div className='lg:w-2/3 w-11/12 flex flex-col lg:justify-end justify-center'>
                            <div className='w-full lg:h-1/2 flex flex-col justify-between'>
                                <div className='flex flex-col gap-4 lg:w-3/4 w-full'>
                                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Strategically integrated, precisely delivered</span>
                                    <span className='font-raleway'>Specializing in providing IT solutions and services tailored to businesses needs</span>
                                    <Button label={'Read More'} onClick={navToAboutUs} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-full flex'>
                        <img src={taglinePic} className='object-cover w-full' alt=''/>
                    </div>
                </div>
                <div className='h-full lg:flex lg:flex-row flex flex-col'>
                    <div className='h-full w-full lg:flex lg:flex-row lg:justify-end flex justify-center'>
                        <div className='lg:w-2/3 w-11/12 flex flex-col lg:justify-end justify-center'>
                            <div className='w-full lg:h-1/2 flex flex-col justify-between'>
                                <div className='flex flex-col gap-10 lg:w-3/4 w-full'>
                                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Client-centered IT solutions</span>
                                    <Button label={'Read More'} onClick={navToAboutUs} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-full flex'>
                        <img src={clientCentered} className='object-cover w-full' alt=''/>
                    </div>
                </div>
                <div className='h-full lg:flex lg:flex-row flex flex-col'>
                    <div className='h-full w-full lg:flex lg:flex-row lg:justify-end flex justify-center'>
                        <div className='lg:w-2/3 w-11/12 flex flex-col lg:justify-end justify-center'>
                            <div className='w-full lg:h-1/2 flex flex-col justify-between'>
                                <div className='flex flex-col gap-10 lg:w-3/4 w-full'>
                                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Trusted by Top 10 National Companies</span>
                                    <Button label={'Read More'} onClick={navToAboutUs} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-full flex'>
                        <img src={trustedPartner} className='object-cover w-full' alt=''/>
                    </div>
                </div>
                <div className='h-full lg:flex lg:flex-row flex flex-col'>
                    <div className='h-full w-full lg:flex lg:flex-row lg:justify-end flex justify-center'>
                        <div className='lg:w-2/3 w-11/12 flex flex-col lg:justify-end justify-center'>
                            <div className='w-full lg:h-1/2 flex flex-col justify-between'>
                                <div className='flex flex-col gap-10 lg:w-3/4 w-full'>
                                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Over two decades of delivering innovation and results</span>
                                    <Button label={'Read More'} onClick={navToAboutUs} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-full flex'>
                        <img src={deliverResults} className='object-cover w-full' alt=''/>
                    </div>
                </div>
            </Carousel>
        </div>
        <div className='h-auto lg:py-40 py-24 flex justify-center items-center w-full relative'>
            <div className='-z-10 absolute w-full flex justify-center items-center'>
                <img src={accent2} className='w-1/2 h-1/2' style={{animation: `spin linear 20s infinite`}} alt=''/>
            </div>
            <div className='lg:w-3/6 w-11/12 h-auto flex flex-col lg:gap-12 gap-5'>
                <div className='flex flex-col gap-1'>
                    <span className='font-plusjakarta text-blueaccent'>Solutions and Services</span>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Get our top solutions & services</span>
                </div>
                <div className='h-full w-full flex gap-5'>
                    <div className='w-full h-full flex flex-col gap-2' style={{animation: `appear-left linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                        {topSolutions.map(({solution, to}, index) => (
                            <Link to={to} key={index} className={`${index === solutionIndex ? 'lg:bg-blue-900 lg:font-bold lg:text-white lg:scale-105 lg:cursor-pointer bg-neutralgray' : 'bg-neutralgray'} w-full rounded-md shadow-md px-4 h-12 flex items-center font-raleway`} onMouseEnter={() => {hoverSolution(index)}}>{solution}</Link>
                        ))}
                    </div>
                    <Link to={topSolutions[solutionIndex].to} className='hidden lg:w-full lg:rounded-md lg:bg-cover lg:bg-center lg:flex lg:flex-col lg:justify-end lg:hover:cursor-pointer' style={{backgroundImage: `url(${topSolutions[solutionIndex].image})`, backgroundSize: 'cover', animation: `appear-right linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                        <div className='w-full h-auto font-raleway text-white p-5 bg-black bg-opacity-75 rounded-md'>{topSolutions[solutionIndex].description}</div>
                    </Link>
                </div>
            </div>
        </div>
        <div className='relative h-auto lg:py-40 py-10 flex justify-center items-center w-full bg-cover bg-center' style={{backgroundImage: `url(${clientbg})`, backgroundSize:`cover`}}>
            <div className='absolute bg-white inset-0 opacity-80 w-full h-full'></div>
            <div className='relative lg:w-4/6 lg:h-4/6 flex flex-col lg:gap-12 gap-2 w-11/12'>
                <div className='flex flex-col gap-1' style={{animation: `appear-bottom linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <span className='font-plusjakarta text-blueaccent'>Principals</span>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Get to know our Principals</span>
                </div>
                <div className='w-full h-full' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <Carousel pauseOnHover theme={customTheme} className='h-80'>
                        {principalsData.map((data,index) => (
                            <div key={index} className='lg:w-4/5 lg:h-80 lg:flex lg:flex-row lg:gap-12 lg:justify-center lg:items-center w-8/12 grid grid-cols-2 place-items-center gap-2'>
                                {data.map((principal, principalIndex) => (
                                    <Link to={principal.link} target='_blank' className='lg:w-52 lg:h-52 w-28 h-28 p-1 rounded-xl bg-white shadow-xl lg:p-4 flex justify-center items-center' key={principalIndex}><img src={principal.image ? require(`../assets/principals/${principal.image}`) : placeholderimg} alt='' className='w-full'/></Link>
                                ))}
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
        <div className='h-auto lg:py-44 py-24 flex justify-center items-center w-full relative overflow-x-hidden'>
            <div className='-z-10 absolute w-full flex justify-end items-center'>
                <img src={accent3} className='w-1/4 h-1/4' style={{animation: `spin linear 20s infinite`}} alt=''/>
            </div>
            <div className='w-full h-auto flex flex-col gap-12 items-center'>
                <div className='lg:w-4/6 w-11/12 flex flex-col gap-1'>
                    <span className='font-plusjakarta text-blueaccent'>Clients</span>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Companies that have worked with us</span>
                </div>
                <div className='w-full h-auto flex flex-col gap-10'>
                    <div className='flex gap-14 w-[8000px] overflow-hidden whitespace-nowrap'>
                        <div className='flex gap-14 h-28' style={{animation: `banner-right-to-left 25s linear infinite`}}>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client1} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client2} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client3} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client4} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client5} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client6} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client7} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client8} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client9} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client10} className='h-full object-contain' alt=''/> </div>
                        </div>
                        <div className='flex gap-14 h-28' style={{animation: `banner-right-to-left 25s linear infinite`}}>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client1} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client2} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client3} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client4} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client5} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client6} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client7} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client8} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client9} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client10} className='h-full object-contain' alt=''/> </div>
                        </div>
                    </div>
                    <div className='flex gap-14 w-[8000px] overflow-hidden whitespace-nowrap'>
                        <div className='flex gap-14 h-28' style={{animation: `banner-left-to-right 25s linear infinite`}}>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client11} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client12} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client13} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client14} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client15} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client16} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client17} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client18} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client19} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client20} className='h-full object-contain' alt=''/> </div>
                        </div>
                        <div className='flex gap-14 h-28' style={{animation: `banner-left-to-right 25s linear infinite`}}>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client11} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client12} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client13} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client14} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client15} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client16} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client17} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client18} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client19} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client20} className='h-full object-contain' alt=''/> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeContents