import React from 'react'
import accent3 from '../assets/Accent3.png'
import placeholderimg from '../assets/placeholder-image.png'
import { Carousel } from 'flowbite-react'
import accent4 from '../assets/Accent4.png'
import { FaQuestion } from "react-icons/fa";
import vision from '../assets/about_us/Vision.jpg'
import mission from '../assets/about_us/Mission.jpg'
import coreValues from '../assets/about_us/Core Values.jpg'
import client1 from '../assets/clients/Client_UOB.png'
import client2 from '../assets/clients/Client_Permata.png'
import client3 from '../assets/clients/Client_Danamon.png'
import client4 from '../assets/clients/Client_Icon+.png'
import client5 from '../assets/clients/Client_Citra.jpeg'
import client6 from '../assets/clients/Client_Coca.png'
import client7 from '../assets/clients/Client_Telkomsel.png'
import client8 from '../assets/clients/Client_Sequis.png'
import client9 from '../assets/clients/Client_Tokio.png'
import client10 from '../assets/clients/Client_KDDI.png'
import client11 from '../assets/clients/Client_Sumber.png'
import client12 from '../assets/clients/Client_Sampoerna.png'
import client13 from '../assets/clients/Client_Mandiri.png'
import client14 from '../assets/clients/Client_Chubb.png'
import client15 from '../assets/clients/Client_BCA.png'
import client16 from '../assets/clients/Client_BI.png'
import client17 from '../assets/clients/Client_CIMB.png'
import client18 from '../assets/clients/Client_BTN.png'
import client19 from '../assets/clients/Client_Astra.png'
import client20 from '../assets/clients/Client_Panin.png'

function AboutUsContents() {
    const carouselContent = [
        {
            title: "Our History",
            subtitle: "Let’s dive into the humble journey of Bajau Escorindo",
            image: "Our History.jpg",
            text: [
                { paragraph: "In 1999, our founders started an IT company. Back then, Indonesia's IT landscape had just gotten to know the internet. With little knowledge in IT and barely enough capital to survive, our founders embarked on what seemed like an impossible journey. Despite the challenges, they saw something that others couldn't–an opportunity to change the game. Through grit, passion, and support from amazing people, Bajau became an open source pioneer in Indonesia who built a strong enough community to alter the mindset of IT enterprises towards embracing open source softwares. Fast forward two decades, now we stand with our own products and solutions that are the driving force for our customers to deliver cost-effective, optimized, and high performance software products."}
            ]
        },
        {
            title: "The ”Bajau” Way of Life",
            subtitle: "Let’s dive into the humble journey of Bajau Escorindo",
            image: "History Name Bajau.jpg",
            text: [
                { paragraph: 'The name “Bajau” was inspired by an indigenous ethnic group that is Suku Bajo. Bajo people, often referred to as "Sea Nomads", reside in coastal areas in Southeast Asia. The Sea Nomads live in such harmony with the sea, that even their physiques allow them to dive up to 70 meters deep and hold their breath underwater for a longer period of time. They live as if the sea has become an extension of themselves.'},
                { paragraph: 'We adopt their way of life as our philosophy: Just as the Bajo people draw strength from nature and become the master of the ocean, we, as Bajau, embrace the evolving needs of the digital world and become an expert in delivering the best technological products and solutions.'}
            ]
        }
    ]

    const customTheme = {
        root: {
          base: "relative h-full w-full",
          leftControl:
            "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
          rightControl:
            "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
        },
        indicators: {
          active: {
            off: "bg-black/50 hover:bg-black/70 dark:bg-gray-800/50 dark:hover:bg-gray-800",
            on: "bg-black",
          },
          base: "h-2 w-2 rounded-full bottom-0",
        },
        control: {
            base: "inline-flex h-8 w-8 items-center justify-center rounded-full group-hover:bg-blueprimary/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-blueprimary sm:h-10 sm:w-10",
            icon: "h-5 w-5 text-blueprimary dark:text-gray-800 sm:h-6 sm:w-6"
        },
        scrollContainer: {
            base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll overflow-x-hidden lg:scroll-smooth rounded-lg",
            snap: "snap-x"
        }
      };
  return (
    <>
    <div className='flex flex-grow w-full flex-col justify-center items-center lg:pt-40 py-24 gap-12'>
        <div className='w-full lg:h-[500px] h-[850px] relative flex justify-start'>
            <div className='-z-10 absolute w-full flex justify-center items-center h-full'>
                <img src={accent4} style={{animation: `spin linear 20s infinite`}} className='w-1/4' alt=''/>
            </div>
            <Carousel pauseOnHover className="w-full" theme={customTheme}>
                {carouselContent.map((content, index) => (
                    <div key={index} className='lg:w-4/6 lg:flex lg:flex-row gap-5 flex flex-col-reverse w-11/12 lg:p-0'>
                        <div className='w-full h-full flex flex-col gap-12'>
                            <div className='flex flex-col gap-1'>
                                <span className='font-plusjakarta text-blueaccent'>{content.subtitle}</span>
                                <span className='font-plusjakarta font-bold lg:text-4xl text-xl'>{content.title}</span>
                            </div>
                            <div className='flex flex-col gap-4 mb-8 lg:mb-0'>
                                {content.text.map((data, index) => (
                                    <div key={index} className='font-raleway'>{data.paragraph}</div>
                                ))}
                            </div>
                        </div>
                        <div className='h-full w-full'>
                            <img src={content.image ? require(`../assets/about_us/${content.image}`) : placeholderimg} className='object-cover h-full w-full rounded-xl' alt=''/>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
        <div className='lg:w-3/6 w-11/12 h-auto flex flex-col gap-1 lg:py-24 py-10'>
            <span className='font-plusjakarta text-blueaccent'>Vision and Mission</span>
            <div className='w-full flex flex-col gap-12'>
                <div className='lg:flex lg:flex-row flex flex-col gap-5 lg:gap-8' style={{animation: `appear-right linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <div className='lg:w-2/5 w-full'>
                        <img src={vision} className='lg:h-60 rounded-xl' alt=''/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <span className='font-plusjakarta font-bold lg:text-4xl text-xl'>Vision</span>
                        <span className='font-raleway'>Become an essential and compassionate partner in achieving digital transformation success</span>
                    </div>
                </div>
                <div className='lg:flex lg:flex-row flex flex-col-reverse gap-5 lg:gap-8' style={{animation: `appear-left linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <div className='flex flex-col gap-2'>
                        <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Mission</span>
                        <span className='font-raleway'>To enhance the value proposition for our stakeholders</span>
                        <span className='font-raleway'>To provide effective, efficient, and customer-focused technology transformation services</span>
                        <span className='font-raleway'>To create technology that is in harmony between human and nature</span>
                        <span className='font-raleway'>To be a humane enterprise corporation</span>
                        <span className='font-raleway'>To be the finest work place for creative people</span>
                    </div>
                    <div className='lg:w-2/5 w-full'>
                        <img src={mission} className='lg:h-60 rounded-xl' alt=''/>
                    </div>
                </div>
            </div>
        </div>
        <div className='w-full flex flex-col items-center relative'>
            <div className='absolute -z-10 w-full h-full'><img src={coreValues} alt='' className='w-full h-full' /></div>
            <div className='w-full h-full bg-black opacity-75 flex flex-col items-center justify-center'>
                <div className='flex flex-col my-10 gap-1 lg:w-4/6 w-11/12'>
                    <span className='font-plusjakarta text-blueaccent'>We uphold these values not only in our way of working, but also in our way of living</span>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl text-white'>Core Values</span>
                </div>
                <div className='lg:w-full w-11/12 lg:flex lg:flex-row flex flex-col gap-2 h-full'>
                    <div className='w-full h-full p-6'>
                        <div className='w-full h-full flex flex-col gap-2 justify-center items-center text-center' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                            <span className='text-blueaccent font-raleway font-bold text-2xl lg:w-3/4 w-full text-center'>Have Grit</span>
                            <span className='text-white font-raleway lg:w-3/4 w-full'>To have grit is to have tenacity and resilience. We believe in achieving our goals with great determination and facing our challenges with the urgency to grow.</span>
                        </div>
                    </div>
                    <div className='w-full h-full p-6'>
                        <div className='w-full h-full flex flex-col gap-2 justify-center items-center text-center' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                            <span className='text-blueaccent font-raleway font-bold text-2xl lg:w-3/4 w-full text-center'>Be Passionate</span>
                            <span className='text-white font-raleway lg:w-3/4 w-full'>We believe that doing things with genuine enthusiasm can bring value to our work and our lives. Being passionate is motivating, inspiring, and fulfilling.</span>
                        </div>
                    </div>
                    <div className='w-full h-full p-6'>
                        <div className='w-full h-full flex flex-col gap-2 justify-center items-center text-center' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                            <span className='text-blueaccent font-raleway font-bold text-2xl lg:w-3/4 w-full text-center'>Strive for Virtuosity</span>
                            <span className='text-white font-raleway lg:w-3/4 w-full'>We put effort and dedication to achieve a high level of skill, creativity, and talent.” or “We strive to achieve excellence; in our services; our products; and in our people.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full lg:py-24 py-16 bg-bluehover flex justify-center items-center'>
                <div className='flex flex-col lg:w-3/6 w-11/12 items-center gap-10 relative'>
                    <div className='absolute w-full h-full flex justify-center items-center'>
                        <div className='h-60 w-80 rounded-lg absolute'>
                            <FaQuestion className='h-full w-full text-blueaccent opacity-20 blur-md'/>
                        </div>
                    </div>
                    <span className='font-plusjakarta lg:text-2xl text-xl font-bold text-white' style={{animation: `appear-bottom linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>Why Choose Us?</span>
                    <div className='flex flex-col gap-6 font-raleway text-white text-center'>
                        <span style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>We are passionate in creating lasting value for our clients. We deliver innovation, reliability, and results <span className='font-bold'>tailored to each client's unique needs</span></span>
                        <span style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>We've built <span className='font-bold'>trusted partnerships with Top 10 national companies.</span> We build relationships by taking time to understand our client's business, goals, and vision.</span>
                        <span style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}><span className='font-bold'>With more than 25 years of journey, we offer unparalleled expertise.</span> We are equipped with deep industry insights and the ability to adapt to evolving trends, ensuring that you stay ahead of the competition.</span>
                        <span style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}><span className='font-bold'>With passion, grit, and virtuosity as our core values,</span> you're gaining a passionate partner invested in your success.</span>
                    </div>
                </div>
            </div>
        </div>
        <div className='h-auto lg:py-44 py-24 flex justify-center items-center w-full relative overflow-x-hidden'>
            <div className='-z-10 absolute w-full flex justify-end items-center'>
                <img src={accent3} className='w-1/4 h-1/4' style={{animation: `spin linear 20s infinite`}} alt=''/>
            </div>
            <div className='w-full h-auto flex flex-col gap-12 items-center'>
                <div className='lg:w-4/6 w-11/12 flex flex-col gap-1'>
                    <span className='font-plusjakarta text-blueaccent'>Clients</span>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>Companies that have worked with us</span>
                </div>
                <div className='w-full h-auto flex flex-col gap-10'>
                    <div className='flex gap-14 w-[8000px] overflow-hidden whitespace-nowrap'>
                        <div className='flex gap-14 h-28' style={{animation: `banner-right-to-left 25s linear infinite`}}>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client1} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client2} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client3} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client4} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client5} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client6} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client7} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client8} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client9} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client10} className='h-full object-contain' alt=''/> </div>
                        </div>
                        <div className='flex gap-14 h-28' style={{animation: `banner-right-to-left 25s linear infinite`}}>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client1} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client2} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client3} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client4} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client5} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client6} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client7} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client8} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client9} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client10} className='h-full object-contain' alt=''/> </div>
                        </div>
                    </div>
                    <div className='flex gap-14 w-[8000px] overflow-hidden whitespace-nowrap'>
                        <div className='flex gap-14 h-28' style={{animation: `banner-left-to-right 25s linear infinite`}}>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client11} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client12} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client13} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client14} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client15} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client16} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client17} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client18} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client19} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client20} className='h-full object-contain' alt=''/> </div>
                        </div>
                        <div className='flex gap-14 h-28' style={{animation: `banner-left-to-right 25s linear infinite`}}>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client11} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client12} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client13} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client14} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client15} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client16} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client17} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client18} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client19} className='h-full object-contain' alt=''/> </div>
                            <div className='w-60 h-24 bg-white rounded-lg shadow-md flex justify-center items-center p-2'><img src={client20} className='h-full object-contain' alt=''/> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default AboutUsContents