import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SolutionsAndServicesContents from '../contents/SolutionsAndServicesContents'

function SolutionsAndServices() {
  return (
    <>
    <div className='min-h-screen flex flex-col overflow-hidden'>
        <Navbar />
        <SolutionsAndServicesContents />
        <Footer />
    </div>
    </>
  )
}

export default SolutionsAndServices