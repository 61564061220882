import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home";
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import BlogsAndNews from './pages/BlogsAndNews';
import Careers from './pages/Careers';
import SolutionsAndServices from './pages/SolutionsAndServices';
import Category from "./pages/Category";
import Solutions from "./pages/Solutions";
import ReadBlog from "./pages/ReadBlog";
import NotFound from "./pages/NotFound";

function App() {
 
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/solutions-and-services" element={<SolutionsAndServices />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/news" element={<BlogsAndNews />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/solutions-and-services/:type/:category" element={<Category />} />
        <Route path="/solutions-and-services/:type/:category/:solution" element={<Solutions />} />
        <Route path="/news/:title" element={<ReadBlog />} />
        <Route path="/*" element={<NotFound />} />
    </Routes>
  </Router>
  );
}

export default App;
