import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import AboutUsContents from '../contents/AboutUsContents'

function AboutUs() {
  return (
    <>
    <div className='min-h-screen flex flex-col'>
        <Navbar />
        <AboutUsContents />
        <Footer />
    </div>
    </>
  )
}

export default AboutUs