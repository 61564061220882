import React, {useState, useEffect} from 'react'
import Button from '../components/Button'
import contactusbg from '../assets/ContactUsbg.jpg'
import {ReactComponent as PhoneIcon} from '../assets/phone-circle-fill.svg'

function ContactUsContents() {
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", `${process.env.REACT_APP_EMAIL_API_KEY}`);
    
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
    
        const res = await fetch("https://api.web3forms.com/submit", {
          mode: 'cors',
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: json
        }).then((res) => res.json());
    
        if (res.success) {
          console.log("Success", res);
          setSuccessMessage("Thank you! Your message has been sent to us!")
          document.getElementById('contact-form').reset()
        }
        if (!res.success) {
            console.log(res)
            setErrorMessage(res.message)
        }
      };
    
      useEffect(() => {
        if(successMessage || errorMessage) {
            setTimeout(() => {
                setSuccessMessage("")
                setErrorMessage("")
            },4000)
        }
      },[successMessage, errorMessage])

  return (
    <>
    <div className='flex flex-grow w-full relative py-20' style={{backgroundImage:`url(${contactusbg})`, backgroundPositionY:`top`}}>
        <div className='absolute w-full bg-black bg-opacity-75 inset-0'></div>
        <div className='w-full relative flex flex-col gap-12 lg:py-24 py-10 items-center'>
            <div className='flex flex-col gap-1 lg:w-4/6 w-11/12'>
                <span className='font-plusjakarta text-blueaccent'>Contact Us</span>
                <span className='font-plusjakarta text-white font-bold lg:text-4xl text-xl'>Have something in mind?</span>
            </div>
            <div className='lg:w-4/6 lg:h-96 lg:flex lg:flex-row lg:gap-8 gap-5 flex flex-col w-11/12'>
                <form className='w-full h-full lg:flex lg:flex-row flex flex-col gap-5' id='contact-form' onSubmit={onSubmit}>
                    <div className='w-full flex flex-col lg:justify-between gap-2'>
                        <div className='flex flex-col gap-1 w-full'>
                            <label htmlFor='name' className='font-plusjakarta text-white text-sm font-bold'>Name</label>
                            <input className='w-full h-10 font-raleway bg-transparent text-white lg:border-b-2 lg:border-neutralgray focus:outline-none' id='name' name='name' autoComplete='off' type='string 'required/>
                            <div className='lg:hidden w-full h-0.5 bg-neutralgray'></div>
                        </div>
                        <div className='flex flex-col gap-1 w-full'>
                            <label htmlFor='email' className='font-plusjakarta text-white text-sm font-bold'>Email</label>
                            <input className='w-full h-10 font-raleway bg-transparent text-white border-b-2 outline-none border-none border-neutralgray ring-0 focus:outline-none appearance-none' id='email' name='email' autoComplete='off' type='email' required/>
                            <div className='w-full h-0.5 bg-neutralgray'></div>
                        </div>
                        <div className='flex flex-col gap-1 w-full'>
                            <label htmlFor='phone' className='font-plusjakarta text-white text-sm font-bold'>Phone</label>
                            <input className='w-full h-10 font-raleway bg-transparent text-white lg:border-b-2 lg:border-neutralgray focus:outline-none' id='phone' name='phone' autoComplete='off' minLength='10' required/>
                            <div className='lg:hidden w-full h-0.5 bg-neutralgray'></div>
                        </div>
                        <div className='flex flex-col gap-1 w-full'>
                            <label htmlFor='company' className='font-plusjakarta text-white text-sm font-bold'>Company</label>
                            <input className='w-full h-10 font-raleway bg-transparent text-white lg:border-b-2 lg:border-neutralgray focus:outline-none' id='company' name='company' autoComplete='off' required/>
                            <div className='lg:hidden w-full h-0.5 bg-neutralgray'></div>
                        </div>
                    </div>
                    <div className='w-full flex flex-col lg:justify-between gap-2'>
                        <div className='flex flex-col gap-1 w-full'>
                            <label htmlFor='message' className='font-plusjakarta text-white text-sm font-bold'>Message</label>
                            <textarea className='w-full h-72 px-0 border-none bg-transparent font-raleway text-white border-b-2 focus:outline-none focus:ring-0 resize-none' id='message' name='message' placeholder='Hello, I want to...' required/>
                            <div className='w-full h-0.5 bg-neutralgray'></div>
                        </div>
                        {(successMessage || errorMessage) && <span className={successMessage ? `text-green-400 lg:w-72 w-full text-center font-bold font-raleway` : 'text-red-400 lg:w-72 w-full text-center font-raleway font-bold'}>{successMessage || errorMessage}</span>}
                        {(successMessage || errorMessage) ? (null) : (
                            <Button buttonType={'submit'} label={'Send'} overrideClass={'mx-auto w-72 h-10 rounded-md bg-blueprimary text-white font-plusjakarta flex justify-center items-center hover:bg-bluehover'} />
                        )}
                    </div>
                </form>
                <div className='lg:w-40 lg:h-full w-full flex flex-row justify-center lg:flex-col gap-2 items-center'>
                    <div className='lg:w-0.5 lg:h-full w-full h-0.5 bg-white hidden lg:block'></div>
                    <div className='font-plusjakarta font-bold lg:text-white text-blueaccent text-2xl'>or</div>
                    <div className='lg:w-0.5 lg:h-full w-full h-0.5 bg-white hidden lg:block'></div>
                </div>
                <div className='lg:w-2/4 lg:h-full w-full px-4 flex flex-col lg:justify-between gap-5 items-center'>
                    <div className='flex flex-col gap-1 text-white items-center'>
                        <span className='font-plusjakarta font-bold text-2xl'>Give us a call</span>
                        <span className='font-raleway'>and have a chat with our team!</span>
                    </div>
                    <div className='flex flex-col gap-2 items-center'>
                        <div className='w-40 h-40'>
                            <PhoneIcon className='w-full h-full object-contain fill-neutralgray' />
                        </div>
                        <div className='flex flex-col gap-1 text-white font-raleway items-center'>
                            <span>Operational Hours:</span>
                            <span>Monday-Friday 09.30 - 17.00</span>
                        </div>
                    </div>
                    <a href='tel:+622150578999' className={'mx-auto w-72 h-10 rounded-md bg-blueprimary text-white font-plusjakarta flex justify-center items-center hover:bg-bluehover'}>Call Us</a>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ContactUsContents